import globalContentDe from '@/assets/data/de/global-content.json'
import globalContentEn from '@/assets/data/en/global-content.json'
import globalContentEs from '@/assets/data/es/global-content.json'
import globalContentFr from '@/assets/data/fr/global-content.json'
import globalContentIt from '@/assets/data/it/global-content.json'
import useApi from '@/composables/api'
import { useMainStore } from '@/store/main'
import { defineStore } from 'pinia'

export const useAuthStore = defineStore('authStore', {
  state: (): AuthStore => ({
    api: useApi(),
    authenticated: false,
    user: null,
    userLocale: 'en'
  }),
  getters: {
    hasCompanyName(state) {
      return state.user?.company_name
    },
    isImpersonating(state) {
      const platinumUsers = ['platinum_admin', 'platinum_engineer', 'platinum_office', 'platinum_super_admin']
      return state.user?.company_name && platinumUsers.includes(state.user?.role) ? true : false
    },
    companyName(state) {
      return state.user?.company_name ? state.user?.company_name : ''
    },
    hasRolePlatinumSuperAdmin(state) {
      return state.user?.role === 'platinum_super_admin'
    },
    hasRolePlatinumEngineer(state) {
      return state.user?.role === 'platinum_engineer'
    },
    hasRolePartnerCustomer(state) {
      return state.user?.role === 'partner_customer'
    }
  },
  actions: {
    async logout() {
      useMainStore().isFetching = true

      await this.api.requestApiCsrfToken()
      const response = await fetch(`${import.meta.env.VITE_LARAVEL_PATH}/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: this.api.getApiHeaders()
      })

      useMainStore().isFetching = false

      if (!response.ok) {
        console.error(response)
      } else {
        localStorage.removeItem('authenticated')
        localStorage.removeItem('user')

        this.authenticated = false
        this.user = null
      }
    },
    setUser() {
      // Get authenticated and user from local storage
      const authenticated = localStorage.getItem('authenticated')
      const user = localStorage.getItem('user')

      if (authenticated && user) {
        this.authenticated = true
        this.user = JSON.parse(user)
        this.userLocale = this.user?.language ?? 'en'
      } else {
        this.authenticated = false
        this.user = null

        localStorage.removeItem('authenticated')
        localStorage.removeItem('user')
      }
    },
    async me() {
      this.setUser()
      try {
        const response = await fetch(`${import.meta.env.VITE_LARAVEL_PATH}/api/v1/user`, {
          method: 'GET',
          credentials: 'include',
          headers: this.api.getApiHeaders()
        })

        useMainStore().navigatorOnline = true
        useMainStore().removeSystemAlerts({ name: 'browsing-offline' })

        if (response.ok) {
          const data = await response.json()

          // Add the authenticated status boolean and the user data object to local storage (for offline usage)
          localStorage.setItem('authenticated', 'true')
          localStorage.setItem('user', JSON.stringify(data))

          this.authenticated = true
          this.user = data
        } else {
          console.error(response)
          this.api.handleErrors(response)
        }
      } catch (error) {
        this.setUser()
        useMainStore().navigatorOnline = false

        let msg
        setTimeout(() => {
          if (this.userLocale === 'de') {
            msg = globalContentDe.browsing_offline
          } else if (this.userLocale === 'es') {
            msg = globalContentEs.browsing_offline
          } else if (this.userLocale === 'fr') {
            msg = globalContentFr.browsing_offline
          } else if (this.userLocale === 'it') {
            msg = globalContentIt.browsing_offline
          } else {
            msg = globalContentEn.browsing_offline
          }

          useMainStore().setSystemAlerts({
            type: 'warning',
            name: 'browsing-offline',
            message: [msg]
          })
        }, 0)

        this.api.checkOnlineStatus()
      }
    }
  }
})
